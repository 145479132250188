import React, { useRef, useMemo } from 'react';
import { useFrame } from '@react-three/fiber';
import { Text } from '@react-three/drei';
import * as THREE from 'three';

const SQSMesh = ({ isHighlighted }) => {
  const groupRef = useRef();
  const papersRef = useRef();
  const beltRef = useRef();

  const bodyColor = '#808080'; // Gray color for the body
  const beltColor = '#000000'; // Black for the belt
  const paperColor = '#FFFFFF'; // White for the paper

  const beltLength = 4;
  const beltWidth = 2;
  const beltThickness = 0.1;
  const paperWidth = 0.5;  // Increased paper size
  const paperHeight = 0.7; // Increased paper size
  const paperCount = 5;

  // Create belt and papers
  const { belt, papers } = useMemo(() => {
    // Create a more detailed belt geometry
    const beltGeometry = new THREE.PlaneGeometry(beltLength, beltWidth, 20, 1);
    const beltMaterial = new THREE.MeshStandardMaterial({
      color: beltColor,
      roughness: 0.7,
      metalness: 0.2,
    });

    // Create a conveyor belt texture with lines
    const canvas = document.createElement('canvas');
    canvas.width = 512;
    canvas.height = 512;
    const ctx = canvas.getContext('2d');
    ctx.fillStyle = beltColor;
    ctx.fillRect(0, 0, 512, 512);
    ctx.strokeStyle = '#FFFFFF';
    ctx.lineWidth = 2;
    for (let i = 0; i < 10; i++) {
      ctx.beginPath();
      ctx.moveTo(0, i * 51.2);
      ctx.lineTo(512, i * 51.2);
      ctx.stroke();
    }
    const beltTexture = new THREE.CanvasTexture(canvas);
    beltTexture.wrapS = THREE.RepeatWrapping;
    beltTexture.wrapT = THREE.RepeatWrapping;
    beltTexture.repeat.set(4, 1);  // Adjusted to make lines more visible
    beltMaterial.map = beltTexture;

    const belt = new THREE.Mesh(beltGeometry, beltMaterial);

    // Create paper-like objects
    const paperGeometry = new THREE.PlaneGeometry(paperWidth, paperHeight);
    const paperMaterial = new THREE.MeshStandardMaterial({
      color: paperColor,
      side: THREE.DoubleSide,
    });
    const papers = new Array(paperCount).fill().map(() => {
      const paper = new THREE.Mesh(paperGeometry, paperMaterial);
      // Add "text lines" to the paper
      const lines = new THREE.Object3D();
      for (let i = 0; i < 4; i++) {  // Added an extra line
        const line = new THREE.Mesh(
          new THREE.PlaneGeometry(paperWidth * 0.8, paperHeight * 0.03),
          new THREE.MeshBasicMaterial({ color: '#000000' })
        );
        line.position.y = (i - 1.5) * paperHeight * 0.2;  // Adjusted positioning
        lines.add(line);
      }
      paper.add(lines);
      return paper;
    });

    return { belt, papers };
  }, [beltColor, paperColor]);

  // Animation for papers and belt
  useFrame((state, delta) => {
    if (papersRef.current) {
      papersRef.current.children.forEach((paper) => {
        paper.position.x -= 0.01;
        if (paper.position.x < -beltLength / 2 - paperWidth / 2) {
          paper.position.x = beltLength / 2 + paperWidth / 2;
        }
      });
    }
    if (beltRef.current && beltRef.current.material.map) {
      beltRef.current.material.map.offset.y -= 0.005;  // Changed direction to match conveyor movement
    }
  });

  return (
    <group ref={groupRef}>
      {/* SQS Body */}
      <mesh position={[0, 0.5, 0]} castShadow receiveShadow>
        <boxGeometry args={[beltLength + 0.5, 1, beltWidth + 0.5]} />
        <meshStandardMaterial color={bodyColor} />
      </mesh>

      {/* Conveyor Belt */}
      <mesh ref={beltRef} position={[0, 1.05, 0]} rotation={[-Math.PI / 2, 0, 0]}>
        <primitive object={belt} />
      </mesh>

      {/* Papers */}
      <group ref={papersRef}>
        {papers.map((paper, index) => (
          <primitive
            key={index}
            object={paper}
            position={[
              (index / (paperCount - 1) - 0.5) * (beltLength - paperWidth),
              1.4 + paperHeight / 2,  // Adjusted height to account for larger papers
              0
            ]}
            rotation={[0, 0, 0]}
          />
        ))}
      </group>

      {/* Support structures */}
      {[-1.5, 1.5].map((x, index) => (
        <mesh key={index} position={[x, -0.25, 0]} castShadow receiveShadow>
          <boxGeometry args={[0.2, 0.5, beltWidth]} />
          <meshStandardMaterial color={bodyColor} />
        </mesh>
      ))}

      {/* SQS Text */}
      <Text
        position={[0, -0.1, beltWidth / 2 + 0.01]}
        rotation={[0, 0, 0]}
        fontSize={0.5}
        color={'#000000'}
      >
        SQS
      </Text>
    </group>
  );
};

export default SQSMesh;