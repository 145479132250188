// src/components/Meshes/DatabaseMesh.js

import React, { useMemo } from 'react';
import * as THREE from 'three';
import { Text } from '@react-three/drei';
import { useFrame } from '@react-three/fiber';

const DatabaseMesh = ({ iconType, isHighlighted }) => {
  // Color configurations
  const colorConfigs = useMemo(() => ({
    DynamoDB: {
      body: isHighlighted ? '#3498DB' : '#2980B9',
      glow: '#00FF00',
      text: '#ECF0F1'
    },
    MongoDB: {
      body: isHighlighted ? '#4CAF50' : '#388E3C',
      glow: '#FFD700',
      text: '#F0F4C3'
    },
    RDS: {
      body: isHighlighted ? '#1E90FF' : '#0073BB',
      glow: '#00CED1',
      text: '#E1F5FE'
    },
    Redshift: {
      body: isHighlighted ? '#8E44AD' : '#6A1B9A',
      glow: '#FF69B4',
      text: '#F3E5F5'
    },
    Aurora: {
      body: isHighlighted ? '#FF8C00' : '#FF7F50',
      glow: '#FF4500',
      text: '#FFF3E0'
    }
  }), [isHighlighted]);

  const colors = colorConfigs[iconType] || colorConfigs.DynamoDB;

  // Custom shader material for glowing effect
  const glowingMaterial = useMemo(() => {
    return new THREE.ShaderMaterial({
      uniforms: {
        time: { value: 0 },
        color: { value: new THREE.Color(colors.glow) }
      },
      vertexShader: `
        varying vec2 vUv;
        void main() {
          vUv = uv;
          gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
        }
      `,
      fragmentShader: `
        uniform float time;
        uniform vec3 color;
        varying vec2 vUv;
        void main() {
          float intensity = 0.5 + 0.5 * sin(vUv.x * 20.0 + time * 2.0);
          gl_FragColor = vec4(color * intensity, 0.7);
        }
      `,
      transparent: true,
      side: THREE.DoubleSide
    });
  }, [colors.glow]);

  // Animation for glowing effect
  useFrame(({ clock }) => {
    glowingMaterial.uniforms.time.value = clock.getElapsedTime();
  });

  return (
    <group>
      {/* Main body cylinders */}
      {[0, 0.8, 1.6, 2.4].map((yPos, index) => (
        <group key={index} position={[0, yPos, 0]}>
          {/* Cylinder body */}
          <mesh castShadow receiveShadow>
            <cylinderGeometry args={[1.2, 1.2, 0.7, 32]} />
            <meshStandardMaterial color={colors.body} />
          </mesh>
          
          {/* Glowing rungs */}
          {[-0.25, 0, 0.25].map((ringY, ringIndex) => (
            <mesh key={ringIndex} position={[0, ringY, 0]} rotation={[Math.PI / 2, 0, 0]}>
              <torusGeometry args={[1.21, 0.02, 16, 100]} />
              <primitive object={glowingMaterial} />
            </mesh>
          ))}
        </group>
      ))}

      {/* Top cap */}
      <mesh position={[0, 2.75, 0]} castShadow receiveShadow>
        <cylinderGeometry args={[1.2, 1.2, 0.1, 32]} />
        <meshStandardMaterial color={colors.body} />
      </mesh>

      {/* Removed the Text component */}
      {/* <Text
        position={[0, 3, 0]}
        rotation={[0, Math.PI / 4, 0]}
        fontSize={0.2}
        color={colors.text}
        anchorX="center"
        anchorY="middle"
      >
        {iconType}
      </Text> */}
    </group>
  );
};

export default DatabaseMesh;
