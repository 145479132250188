// src/components/UI/GridComponent.js

import React from 'react';
import DraggableIcon3D from '../DraggableIcon3D';
import ConnectionLine from './ConnectionLine';
import { Grid } from '@react-three/drei';

const GridComponent = ({
  icons,
  setIcons,
  onIconClick,
  connections,
  setConnections,
  selectedIcon,
  menuOpenIconId,
  onRename,
  onStartMove,
  onStartConnect,
  movingIconId,
  onMoveEnd,
  setIconPosition,
  onConnectionClick,
  menuOpenConnectionId,
  onDeleteConnection,
  onEditConnection,
  isCurved,
  onMenuToggle,
  highlightedElements,
  viewMode,
  showConnectionLabels
}) => {
  return (
    <group>
      <Grid
        infiniteGrid={true}
        plane="xz"
        cellSize={5}
        cellThickness={0.5}
        cellColor={'#444'}
        sectionSize={100}
        sectionThickness={1}
        sectionColor={'#888'}
        fadeDistance={10000}
        fadeStrength={0}
        followCamera={true}
      />
      {icons.map((icon) => (
        <DraggableIcon3D
          key={icon.id}
          icon={icon}
          isSelected={selectedIcon && selectedIcon.id === icon.id}
          menuOpenIconId={menuOpenIconId}
          onSelect={onIconClick}
          onMenuToggle={onMenuToggle}
          onDelete={(id) =>
            setIcons((prevIcons) => prevIcons.filter((icon) => icon.id !== id))
          }
          onRename={onRename}
          onStartMove={onStartMove}
          onStartConnect={onStartConnect}
          movingIconId={movingIconId}
          onMoveEnd={onMoveEnd}
          setIconPosition={setIconPosition}
          isHighlighted={highlightedElements.includes(icon.id)}
          viewMode={viewMode} // Pass viewMode to DraggableIcon3D
        />
      ))}
      {connections.map((connection) => (
        <ConnectionLine
          key={connection.id}
          connection={connection}
          icons={icons}
          onClick={onConnectionClick}
          menuOpenConnectionId={menuOpenConnectionId}
          onDeleteConnection={onDeleteConnection}
          onEditConnection={onEditConnection}
          isCurved={isCurved}
          isHighlighted={highlightedElements.includes(connection.id)}
          showLabel={showConnectionLabels} // Pass the prop
        />
      ))}
    </group>
  );
};

export default GridComponent;
