// src/components/Meshes/IconMesh.js

import React from 'react';
import S3BucketMesh from './S3BucketMesh';
import DatabaseMesh from './DatabaseMesh';
import VaultMesh from './VaultMesh';
import FileMesh from './FileMesh';
import SQSMesh from './SQSMesh'; // Import SQSMesh
import DefaultIconMesh from './DefaultIconMesh';

const IconMesh = ({ icon, isHighlighted }) => {
  // Define an array of all database types handled by DatabaseMesh
  const databaseTypes = ['DynamoDB', 'MongoDB', 'RDS', 'Redshift', 'Aurora'];

  if (icon.iconType === 'S3') {
    return <S3BucketMesh isHighlighted={isHighlighted} />;
  } else if (databaseTypes.includes(icon.iconType)) {
    return (
      <DatabaseMesh
        iconType={icon.iconType}
        isHighlighted={isHighlighted}
      />
    );
  } else if (icon.iconType === 'Vault') {
    return <VaultMesh isHighlighted={isHighlighted} />;
  } else if (icon.iconType === 'SFTP') {
    return <FileMesh isHighlighted={isHighlighted} />;
  } else if (icon.iconType === 'SQS') { // Add condition for SQS
    return <SQSMesh isHighlighted={isHighlighted} />;
  } else {
    return <DefaultIconMesh icon={icon} isHighlighted={isHighlighted} />;
  }
};

export default IconMesh;
