// frontend/src/components/Workflows/WorkflowPanel.js

import React from 'react';

const WorkflowPanel = ({
  workflows,
  onCreate,
  onEdit,
  onDelete,
  onStartPresentation,
}) => {
  return (
    <div className="workflow-panel">
      <h3 className="workflow-title">Workflows</h3>
      <button className="workflow-button" onClick={onCreate}>
        Create New Workflow
      </button>
      {workflows.length === 0 ? (
        <p>No workflows available.</p>
      ) : (
        <ul className="workflow-list">
          {workflows.map((workflow) => (
            <li key={workflow.id} className="workflow-item">
              <span className="workflow-name">{workflow.name}</span>
              <div className="workflow-actions">
                <button
                  className="workflow-action-button"
                  onClick={() => onStartPresentation(workflow.id)}
                >
                  Play
                </button>
                <button
                  className="workflow-action-button"
                  onClick={() => onEdit(workflow.id)}
                >
                  Edit
                </button>
                <button
                  className="workflow-action-button"
                  onClick={() => onDelete(workflow.id)}
                >
                  Delete
                </button>
              </div>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default WorkflowPanel;
