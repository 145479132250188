import React, { useRef } from 'react';
import { useFrame } from '@react-three/fiber';
import * as THREE from 'three';

const VaultMesh = ({ isHighlighted }) => {
  const groupRef = useRef();
  const shackleRef = useRef();

  // Updated to a lighter tan color
  const bodyColor = isHighlighted ? '#F5E1A4' : '#F5DEB3'; // Lightened Tan color
  const shackleColor = '#4A4A4A'; // Dark gray for shackle

  const animationDuration = 16; // Increased duration for slower animation

  useFrame((state) => {
    const elapsedTime = state.clock.getElapsedTime();
    const time = (elapsedTime % animationDuration) / animationDuration; // Normalized time [0,1)

    if (time >= 0 && time < 0.3) {
      // 1. Unlocked State
      shackleRef.current.rotation.y = 0;
      shackleRef.current.position.y = 1.1;
    } else if (time >= 0.3 && time < 0.4) {
      // 2. Swinging Closed
      const swingProgress = (time - 0.3) / 0.1; // Progress: 0 to 1
      shackleRef.current.rotation.y = Math.PI * swingProgress; // Rotate from 0 to PI
      shackleRef.current.position.y = 1.1; // Maintain position during swing
    } else if (time >= 0.4 && time < 0.5) {
      // 3. Dropping to Lock
      const dropProgress = (time - 0.4) / 0.1; // Progress: 0 to 1
      shackleRef.current.rotation.y = Math.PI; // Ensure rotation is PI
      shackleRef.current.position.y = THREE.MathUtils.lerp(1.1, 0.8, dropProgress); // Drop from 1.1 to 0.8
    } else if (time >= 0.5 && time < 0.8) {
      // 4. Locked State
      shackleRef.current.rotation.y = Math.PI;
      shackleRef.current.position.y = 0.8;
    } else if (time >= 0.8 && time < 0.9) {
      // 5. Rising to Unlock
      const riseProgress = (time - 0.8) / 0.1; // Progress: 0 to 1
      shackleRef.current.rotation.y = Math.PI; // Maintain rotation during rise
      shackleRef.current.position.y = THREE.MathUtils.lerp(0.8, 1.1, riseProgress); // Rise from 0.8 to 1.1
    } else if (time >= 0.9 && time < 1.0) {
      // 6. Swinging Open
      const swingProgress = (time - 0.9) / 0.1; // Progress: 0 to 1
      shackleRef.current.rotation.y = Math.PI * (1 - swingProgress); // Rotate from PI to 0
      shackleRef.current.position.y = 1.1; // Maintain position during swing
    }
  });

  return (
    <group ref={groupRef}>
      {/* Vault Body */}
      <mesh castShadow receiveShadow>
        <boxGeometry args={[1.5, 2, 0.8]} />
        <meshStandardMaterial color={bodyColor} metalness={0.1} roughness={0.8} />
      </mesh>

      {/* Keyhole */}
      <mesh position={[0, -0.3, 0.41]} castShadow receiveShadow>
        <cylinderGeometry args={[0.2, 0.2, 0.05, 32]} />
        <meshStandardMaterial color={shackleColor} metalness={0.3} roughness={0.7} />
      </mesh>
      <mesh position={[0, -0.6, 0.41]} castShadow receiveShadow>
        <boxGeometry args={[0.1, 0.4, 0.05]} />
        <meshStandardMaterial color={shackleColor} metalness={0.3} roughness={0.7} />
      </mesh>

      {/* Shackle */}
      <group ref={shackleRef} position={[0.55, 1.1, 0]}>
        <mesh position={[-0.55, 0, 0]} castShadow receiveShadow>
          <torusGeometry args={[0.4, 0.08, 16, 100, Math.PI]} />
          <meshStandardMaterial color={shackleColor} metalness={0.5} roughness={0.5} />
        </mesh>
        {/* Shackle legs */}
        <mesh position={[-0.95, -0.4, 0]} castShadow receiveShadow>
          <cylinderGeometry args={[0.08, 0.08, 0.8, 16]} />
          <meshStandardMaterial color={shackleColor} metalness={0.5} roughness={0.5} />
        </mesh>
        <mesh position={[-0.15, -0.4, 0]} castShadow receiveShadow>
          <cylinderGeometry args={[0.08, 0.08, 0.8, 16]} />
          <meshStandardMaterial color={shackleColor} metalness={0.5} roughness={0.5} />
        </mesh>
      </group>
    </group>
  );
};

export default VaultMesh;
