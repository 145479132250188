// src/components/UI/DraggableIcon3D.js

import React, { useRef, useState } from 'react';
import { useThree, useLoader } from '@react-three/fiber';
import { Text, Html } from '@react-three/drei';
import * as THREE from 'three';
import IconMesh from './Meshes/IconMesh';

const DraggableIcon3D = ({
  icon,
  isSelected,
  menuOpenIconId,
  onSelect,
  onMenuToggle,
  onDelete,
  onRename,
  onStartMove,
  onStartConnect,
  movingIconId,
  onMoveEnd,
  setIconPosition,
  isHighlighted,
  viewMode, // Accept viewMode prop
}) => {
  const meshRef = useRef();
  const [isDragging, setIsDragging] = useState(false);
  const { camera, gl } = useThree();

  const isMenuOpen = menuOpenIconId === icon.id;

  // Load the icon texture
  const iconTexture = useLoader(
    THREE.TextureLoader,
    `${process.env.PUBLIC_URL}/icons/${icon.iconType}.png`
  );

  // Handle pointer events for dragging
  const handlePointerDown = (event) => {
    if (movingIconId === icon.id) {
      event.stopPropagation();
      setIsDragging(true);
      event.target.setPointerCapture(event.pointerId);
    }
  };

  const handlePointerMove = (event) => {
    if (isDragging) {
      event.stopPropagation();
      const [x, y] = [event.clientX, event.clientY];
      const rect = gl.domElement.getBoundingClientRect();
      const ndcX = ((x - rect.left) / rect.width) * 2 - 1;
      const ndcY = -((y - rect.top) / rect.height) * 2 + 1;

      const raycaster = new THREE.Raycaster();
      raycaster.setFromCamera({ x: ndcX, y: ndcY }, camera);
      const plane = new THREE.Plane(new THREE.Vector3(0, 1, 0), 0);
      const intersectPoint = new THREE.Vector3();
      raycaster.ray.intersectPlane(plane, intersectPoint);

      if (intersectPoint) {
        const snapSize = 5;
        const snappedX = Math.round(intersectPoint.x / snapSize) * snapSize;
        const snappedZ = Math.round(intersectPoint.z / snapSize) * snapSize;

        setIconPosition(icon.id, [snappedX, 0.5, snappedZ]);
      }
    }
  };

  const handlePointerUp = (event) => {
    if (isDragging) {
      event.stopPropagation();
      setIsDragging(false);
      onMoveEnd();
      event.target.releasePointerCapture(event.pointerId);
    }
  };

  const handleClick = (event) => {
    event.stopPropagation();
    if (movingIconId !== icon.id) {
      onSelect(icon);
      onMenuToggle(icon);
    }
  };

  // Truncate name if longer than 20 characters
  const displayName =
    icon.name.length > 20 ? icon.name.substring(0, 20) + '...' : icon.name;

  // Determine label position based on viewMode
  const labelOffset = 1.6; // Base distance from the center
  const additionalOffset = 0.5; // Additional distance to prevent overlap
  const labelPosition = viewMode === 'top-down'
    ? [0, 0.1, labelOffset + additionalOffset] // Further along Z-axis in top-down view
    : [0, 0.2, labelOffset + additionalOffset]; // Higher Y and further Z in isometric view

  return (
    <mesh
      ref={meshRef}
      position={icon.position}
      onPointerDown={handlePointerDown}
      onPointerMove={handlePointerMove}
      onPointerUp={handlePointerUp}
      onClick={handleClick}
      castShadow
      receiveShadow
    >
      {/* Render the icon based on view mode */}
      {viewMode === 'top-down' ? (
        // Render the plane with the texture for top-down view
        <mesh rotation={[-Math.PI / 2, 0, 0]}>
          <planeGeometry args={[3, 3]} />
          <meshStandardMaterial map={iconTexture} transparent />
        </mesh>
      ) : (
        // Render the 3D icon for isometric view
        <IconMesh icon={icon} isHighlighted={isHighlighted || isSelected} />
      )}

      {/* Render the text label */}
      <Text
        position={labelPosition} // Dynamic position based on viewMode
        rotation={[-Math.PI / 2, 0, 0]} // Keep text flat on XZ plane
        fontSize={1} // Adjust font size as needed
        color="black"
        anchorX="center"
        anchorY="middle"
        className="text-label"
        title={icon.name}
        depthTest={false} // Ensure text is rendered on top
        transparent={true}
        renderOrder={999} // Ensure text is rendered after other objects
      >
        {displayName}
      </Text>

      {/* Selection Outline */}
      {(isSelected || isHighlighted) && (
        <lineSegments>
          <edgesGeometry
            attach="geometry"
            args={[new THREE.BoxGeometry(3.2, 1.2, 3.2)]}
          />
          <lineBasicMaterial attach="material" color="yellow" linewidth={2} />
        </lineSegments>
      )}

      {/* Radial Menu */}
      {isMenuOpen && (
        <Html position={[0, 0, 0]} center>
          <div className="radial-menu">
            <button
              className="radial-button"
              onClick={(e) => {
                e.stopPropagation();
                onDelete(icon.id);
              }}
              style={{ '--angle': '0deg', '--index': 0 }}
            >
              Delete
            </button>
            <button
              className="radial-button"
              onClick={(e) => {
                e.stopPropagation();
                onRename(icon);
              }}
              style={{ '--angle': '90deg', '--index': 1 }}
            >
              Rename
            </button>
            <button
              className="radial-button"
              onClick={(e) => {
                e.stopPropagation();
                onStartMove(icon);
              }}
              style={{ '--angle': '180deg', '--index': 2 }}
            >
              Move
            </button>
            <button
              className="radial-button"
              onClick={(e) => {
                e.stopPropagation();
                onStartConnect(icon);
              }}
              style={{ '--angle': '270deg', '--index': 3 }}
            >
              Connect
            </button>
          </div>
        </Html>
      )}
    </mesh>
  );
};

export default DraggableIcon3D;
