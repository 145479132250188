// src/App.js

import React, { useState, useRef } from 'react';
import './App.css';
import { Canvas } from '@react-three/fiber';

import IconPalette from './components/UI/IconPalette';
import ExportButton from './components/UI/ExportButton';
import ConnectModal from './components/Modals/ConnectModal';
import ConnectionEditModal from './components/Modals/ConnectionEditModal';

import WorkflowPanel from './components/Workflows/WorkflowPanel';
import PresentationMode from './components/Workflows/PresentationMode';
import { WorkflowModals } from './components/Modals/WorkflowModals';
import SceneContent from './SceneContent';

const App = () => {
  const [icons, setIcons] = useState([]);
  const [connections, setConnections] = useState([]);
  const [selectedIcon, setSelectedIcon] = useState(null);
  const [menuOpenIconId, setMenuOpenIconId] = useState(null);
  const [movingIconId, setMovingIconId] = useState(null);
  const [connectModalOpen, setConnectModalOpen] = useState(false);
  const [connectFromIcon, setConnectFromIcon] = useState(null);
  const [connectionModalOpen, setConnectionModalOpen] = useState(false);
  const [selectedConnectionIndex, setSelectedConnectionIndex] = useState(null);
  const [creatingIcon, setCreatingIcon] = useState(null);
  const [isCurved, setIsCurved] = useState(false);
  const [viewMode, setViewMode] = useState('isometric');
  const [menuOpenConnectionId, setMenuOpenConnectionId] = useState(null);

  // Workflow-related states
  const [workflows, setWorkflows] = useState([]);
  const [currentWorkflow, setCurrentWorkflow] = useState(null);
  const [presentationMode, setPresentationMode] = useState(false);
  const [workflowModals, setWorkflowModals] = useState({
    create: false,
    edit: false,
  });
  const [selectedWorkflowId, setSelectedWorkflowId] = useState(null);

  // Presentation-related states
  const [currentStepIndex, setCurrentStepIndex] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);

  // State variable for label visibility
  const [showConnectionLabels, setShowConnectionLabels] = useState(true);

  // Reference to the hidden file input
  const fileInputRef = useRef(null);

  // Handle exporting diagram data as JSON
  const handleExportDiagram = () => {
    const fileName = prompt(
      'Enter a name for the JSON file (without extension):',
      'diagram'
    );
    if (!fileName) {
      return; // User cancelled
    }
    const data = {
      icons,
      connections,
      workflows, // Include workflows in the exported data
    };

    const jsonData = JSON.stringify(data, null, 2);

    const blob = new Blob([jsonData], { type: 'application/json' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = `${fileName}.json`;
    link.click();

    // Revoke the object URL after download
    URL.revokeObjectURL(link.href);
  };

  // Handle loading diagram from JSON file
  const handleLoadDiagram = (event) => {
    const file = event.target.files[0];
    if (!file) return;

    const reader = new FileReader();
    reader.onload = (e) => {
      try {
        const data = JSON.parse(e.target.result);
        const {
          icons: loadedIcons,
          connections: loadedConnections,
          workflows: loadedWorkflows,
        } = data;

        // Validate and assign default properties to connections
        const connectionsWithId = (loadedConnections || []).map((conn, index) => {
          // Assign a unique ID if missing
          const id = conn.id || Date.now() + Math.random();

          // Assign default direction if missing
          const direction = conn.direction || 'start-to-end';

          // Assign default color if missing
          const color = conn.color || 'black';

          // Assign default name if missing
          const name = conn.name || '';

          // Validate startId and endId
          if (!loadedIcons.find((icon) => icon.id === conn.startId)) {
            console.warn(
              `Imported connection at index ${index} has invalid startId: ${conn.startId}`
            );
          }
          if (!loadedIcons.find((icon) => icon.id === conn.endId)) {
            console.warn(
              `Imported connection at index ${index} has invalid endId: ${conn.endId}`
            );
          }

          return {
            ...conn,
            id,
            direction,
            color,
            name,
          };
        });

        setIcons(loadedIcons || []);
        setConnections(connectionsWithId);
        setWorkflows(loadedWorkflows || []); // Load workflows
      } catch (error) {
        console.error('Error parsing JSON:', error);
        alert('Failed to load diagram: Invalid JSON file.');
      }
    };
    reader.readAsText(file);

    // Reset the input value to allow loading the same file again if needed
    event.target.value = '';
  };

  // Handle starting to create a new icon
  const handleStartCreate = (name) => {
    const limitedName = name.length > 20 ? name.substring(0, 20) + '...' : name;
    const icon = {
      id: Date.now(),
      name: limitedName,
      iconType: name,
      position: [0, 0.5, 0],
    };
    setCreatingIcon(icon);
  };

  // Handle moving an icon
  const setIconPosition = (id, newPosition) => {
    setIcons((prevIcons) =>
      prevIcons.map((icon) =>
        icon.id === id ? { ...icon, position: newPosition } : icon
      )
    );
  };

  const handleMoveEnd = () => {
    setMovingIconId(null);
  };

  // Handle icon selection and menu toggle
  const handleIconClick = (icon) => {
    setSelectedIcon(icon);
  };

  const handleMenuToggle = (icon) => {
    setMenuOpenIconId((prevId) => (prevId === icon.id ? null : icon.id));
    setMenuOpenConnectionId(null); // Close connection menus when opening an icon menu
  };

  // Handle deletion of components
  const handleDelete = (id) => {
    setIcons((prevIcons) => prevIcons.filter((icon) => icon.id !== id));
    setConnections((prevConnections) =>
      prevConnections.filter((conn) => conn.startId !== id && conn.endId !== id)
    );
    if (selectedIcon && selectedIcon.id === id) {
      setSelectedIcon(null);
    }
    if (menuOpenIconId === id) {
      setMenuOpenIconId(null);
    }
    if (movingIconId === id) {
      setMovingIconId(null);
    }
    // Remove steps involving the deleted icon from workflows
    setWorkflows((prevWorkflows) =>
      prevWorkflows.map((workflow) => ({
        ...workflow,
        steps: workflow.steps.filter(
          (step) => !(step.type === 'component' && step.targetId === id)
        ),
      }))
    );
  };

  // Handle renaming of components
  const handleRename = (icon) => {
    const input = prompt('Enter new name:', icon.name);
    if (input && input.trim() !== '') {
      const limitedName =
        input.length > 20 ? input.substring(0, 20) + '...' : input;
      setIcons((prevIcons) =>
        prevIcons.map((i) => (i.id === icon.id ? { ...i, name: limitedName } : i))
      );
    }
  };

  // Handle starting to move an icon
  const handleStartMove = (icon) => {
    setMovingIconId(icon.id);
    setMenuOpenIconId(null);
  };

  // Handle starting to connect from an icon
  const handleStartConnect = (icon) => {
    setConnectFromIcon(icon);
    setConnectModalOpen(true);
    setMenuOpenIconId(null);
  };

  // Handle creating a connection
  const handleCreateConnection = (
    targetIconId,
    direction,
    connectionName
  ) => {
    const targetIcon = icons.find((icon) => icon.id === targetIconId);
    if (!targetIcon) {
      alert('Invalid component selected.');
      return;
    }

    // Prevent self-connection and duplicate connections
    const exists = connections.some(
      (conn) =>
        (conn.startId === connectFromIcon.id &&
          conn.endId === targetIcon.id &&
          conn.direction === direction) ||
        (conn.startId === targetIcon.id &&
          conn.endId === connectFromIcon.id &&
          conn.direction === direction)
    );
    if (exists) {
      alert(
        'Connection already exists between these components in this direction.'
      );
      return;
    }

    // Add the connection with the provided name/type
    setConnections((prevConnections) => [
      ...prevConnections,
      {
        id: Date.now(), // Add unique ID for the connection
        startId:
          direction === 'start-to-end' ? connectFromIcon.id : targetIcon.id,
        endId:
          direction === 'start-to-end' ? targetIcon.id : connectFromIcon.id,
        direction,
        color: 'black', // Default color
        name: connectionName || 'Connection', // Add name or type
      },
    ]);

    setConnectModalOpen(false);
    setConnectFromIcon(null);
  };

  // Handle connection click
  const handleConnectionClick = (connectionId) => {
    setMenuOpenConnectionId((prevId) =>
      prevId === connectionId ? null : connectionId
    );
    setMenuOpenIconId(null); // Close any open icon menus
  };

  // Handle deleting a connection
  const handleDeleteConnection = (connectionId) => {
    const deletedConnection = connections.find(
      (conn) => conn.id === connectionId
    );
    setConnections((prevConnections) =>
      prevConnections.filter((conn) => conn.id !== connectionId)
    );
    setMenuOpenConnectionId(null);

    // Remove steps involving the deleted connection from workflows
    setWorkflows((prevWorkflows) =>
      prevWorkflows.map((workflow) => ({
        ...workflow,
        steps: workflow.steps.filter(
          (step) =>
            !(
              step.targetId === deletedConnection.id &&
              step.type === 'connection'
            )
        ),
      }))
    );
  };

  // Handle editing a connection
  const handleEditConnection = (connectionId) => {
    const index = connections.findIndex((conn) => conn.id === connectionId);
    setSelectedConnectionIndex(index);
    setConnectionModalOpen(true);
    setMenuOpenConnectionId(null);
  };

  // Toggle function for line type
  const toggleLineType = () => {
    setIsCurved((prev) => !prev);
  };

  // Handle Load Diagram button click
  const handleLoadButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  // Workflow management handlers
  const handleCreateWorkflow = (workflow) => {
    setWorkflows((prevWorkflows) => [...prevWorkflows, workflow]);
    setWorkflowModals({ ...workflowModals, create: false });
  };

  const handleEditWorkflow = (updatedWorkflow) => {
    setWorkflows((prevWorkflows) =>
      prevWorkflows.map((wf) =>
        wf.id === updatedWorkflow.id ? updatedWorkflow : wf
      )
    );
    setWorkflowModals({ ...workflowModals, edit: false });
    setSelectedWorkflowId(null);
  };

  const handleDeleteWorkflow = (id) => {
    setWorkflows((prevWorkflows) => prevWorkflows.filter((wf) => wf.id !== id));
  };

  const handleStartPresentation = (workflowId) => {
    const workflow = workflows.find((wf) => wf.id === workflowId);
    if (workflow) {
      setCurrentWorkflow(workflow);
      setCurrentStepIndex(0);
      setIsPlaying(true);
      setPresentationMode(true);
      // No need to modify showConnectionLabels here
    }
  };

  const handleExitPresentation = () => {
    setPresentationMode(false);
    setCurrentWorkflow(null);
    setIsPlaying(false);
    setCurrentStepIndex(0);
  };

  // Handler for toggling connection labels
  const handleToggleLabels = () => {
    setShowConnectionLabels((prev) => !prev);
  };

  return (
    <div className="app">
      {/* Sidebar Section */}
      <div className="sidebar">
        <h2>Infrastructure</h2>
        {/* Icon Palette */}
        <IconPalette onStartCreate={handleStartCreate} />
        {/* Buttons */}
        <div className="button-container">
          {/* Export Buttons */}
          <div className="export-buttons">
            <button className="export-button" onClick={handleExportDiagram}>
              Export Diagram JSON
            </button>
            <ExportButton />
          </div>
          <button className="load-button" onClick={handleLoadButtonClick}>
            Load Diagram
          </button>
          <input
            type="file"
            ref={fileInputRef}
            accept=".json"
            style={{ display: 'none' }}
            onChange={handleLoadDiagram}
          />
          {/* Toggle Button for Line Type */}
          <button className="toggle-button" onClick={toggleLineType}>
            {isCurved ? 'Switch to Straight Lines' : 'Switch to Curved Lines'}
          </button>
          {/* Toggle Labels Button */}
          <button className="toggle-button" onClick={handleToggleLabels}>
            {showConnectionLabels ? 'Hide Labels' : 'Show Labels'}
          </button>
        </div>
        {/* Workflow Panel */}
        <WorkflowPanel
          workflows={workflows}
          onCreate={() =>
            setWorkflowModals({ ...workflowModals, create: true })
          }
          onEdit={(id) => {
            setSelectedWorkflowId(id);
            setWorkflowModals({ ...workflowModals, edit: true });
          }}
          onDelete={handleDeleteWorkflow}
          onStartPresentation={handleStartPresentation}
        />
      </div>

      {/* Main Content Section */}
      <div style={{ flex: 1, position: 'relative' }}>
        {/* Controls Container */}
        <div className="controls-container">
          {/* Toggle View Button */}
          <button
            onClick={() =>
              setViewMode(viewMode === 'isometric' ? 'top-down' : 'isometric')
            }
            className="view-toggle-button"
          >
            {viewMode === 'isometric' ? 'Top-Down View' : 'Isometric View'}
          </button>
        </div>

        <Canvas
          orthographic
          camera={{
            position: viewMode === 'isometric' ? [30, 35, 30] : [0, 100, 0],
            zoom: 50,
            near: -1000,
            far: 1000,
            up: [0, 1, 0],
          }}
          shadows
          style={{ width: '100%', height: '100%', background: '#ffffff' }}
          onPointerMissed={() => {
            setSelectedIcon(null);
            setMenuOpenIconId(null);
            setMenuOpenConnectionId(null);
          }}
        >
          <SceneContent
            icons={icons}
            setIcons={setIcons}
            onIconClick={handleIconClick}
            connections={connections}
            setConnections={setConnections}
            selectedIcon={selectedIcon}
            menuOpenIconId={menuOpenIconId}
            onRename={handleRename}
            onStartMove={handleStartMove}
            onStartConnect={handleStartConnect}
            movingIconId={movingIconId}
            onMoveEnd={handleMoveEnd}
            setIconPosition={setIconPosition}
            onConnectionClick={handleConnectionClick}
            menuOpenConnectionId={menuOpenConnectionId}
            onDeleteConnection={handleDeleteConnection}
            onEditConnection={handleEditConnection}
            isCurved={isCurved}
            onMenuToggle={handleMenuToggle}
            // Workflow-related props
            currentWorkflow={currentWorkflow}
            presentationMode={presentationMode}
            currentStepIndex={currentStepIndex}
            setCurrentStepIndex={setCurrentStepIndex}
            isPlaying={isPlaying}
            setIsPlaying={setIsPlaying}
            viewMode={viewMode}
            creatingIcon={creatingIcon}
            setCreatingIcon={setCreatingIcon}
            showConnectionLabels={showConnectionLabels} // Pass the prop
          />
        </Canvas>
        {/* Presentation Controls */}
        {presentationMode && currentWorkflow && (
          <PresentationMode
            workflow={currentWorkflow}
            onExit={handleExitPresentation}
            currentStepIndex={currentStepIndex}
            setCurrentStepIndex={setCurrentStepIndex}
            isPlaying={isPlaying}
            setIsPlaying={setIsPlaying}
          />
        )}
      </div>

      {/* Connection Modal */}
      {connectModalOpen && (
        <ConnectModal
          icons={icons}
          fromIcon={connectFromIcon}
          onCreateConnection={handleCreateConnection}
          onClose={() => {
            setConnectModalOpen(false);
            setConnectFromIcon(null);
          }}
        />
      )}

      {/* Connection Edit Modal */}
      {connectionModalOpen && (
        <ConnectionEditModal
          connection={connections[selectedConnectionIndex]}
          onUpdateConnection={(updatedConnection) => {
            setConnections((prevConnections) =>
              prevConnections.map((conn, idx) =>
                idx === selectedConnectionIndex
                  ? { ...conn, ...updatedConnection }
                  : conn
              )
            );
            setConnectionModalOpen(false);
            setSelectedConnectionIndex(null);
          }}
          onClose={() => {
            setConnectionModalOpen(false);
            setSelectedConnectionIndex(null);
          }}
        />
      )}

      {/* Workflow Modals */}
      <WorkflowModals
        isOpen={workflowModals}
        onRequestClose={() =>
          setWorkflowModals({ create: false, edit: false })
        }
        onCreateWorkflow={handleCreateWorkflow}
        onEditWorkflow={handleEditWorkflow}
        icons={icons}
        connections={connections}
        selectedWorkflow={
          workflows.find((wf) => wf.id === selectedWorkflowId) || null
        }
      />
    </div>
  );
};

export default App;
